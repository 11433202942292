.assets-management-page {
    .page-header {
        text-align: right;
    }

    table {
        .image-cell {
            width: 200px;

            img {
                width: 100%;
            }
        }

        .btns {
            display: flex;
            align-items: center;

            button {
                margin: 0 5px;
            }
        }
    }
}