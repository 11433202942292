.publications-page {
    .page-header {
        text-align: right;
        margin-bottom: 20px;
    }

    .btns {
        display: flex;

        button {
            margin: 0 10px;
        }
    }
}