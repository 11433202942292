.publication-page {
    .content-cell {
        width: 500px;
    }

    .assets-cell {
        width: 500px;

        .assets {
            display: flex;
            flex-wrap: wrap;

            img {
                width: 100px;
                cursor: pointer;
            }
        }
    }
}