.assets-search-modal {
    .form {
        display: flex;

        form {
            flex: 1;
        }

        button {
            margin-left: 10px;
        }
    }

    .selected-assets {
        margin-top: 20px;

        h2 {
            font-size: 20px;
        }
    }

    .result {
        margin-top: 10px;
    }

    .assets {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        img {
            width: 100px;
            cursor: pointer;
        }
    }
}